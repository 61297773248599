import { Component, Injectable, AfterViewInit } from "@angular/core";

declare var $: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
@Injectable()
export class AppComponent implements AfterViewInit {
  private title: string = "Start Bootstrap";

  ngAfterViewInit(): void {
    // jQuery for page scrolling feature - requires jQuery Easing plugin
    $("a.page-scroll").on("click", function (event) {
      var $anchor = $(this);
      $("html, body")
        .stop()
        .animate(
          {
            scrollTop: $($anchor.attr("href")).offset().top - 50,
          },
          1250,
          "easeInOutExpo"
        );
      event.preventDefault();
    });

    // Highlight the top nav as scrolling occurs
    $("body").scrollspy({
      target: ".navbar-fixed-top",
      offset: 100,
    });

    // Closes the Responsive Menu on Menu Item Click
    $(".navbar-collapse ul li a").click(function () {
      $(".navbar-toggle:visible").click();
    });

    // Offset for Main Navigation

    $("#mainNav").affix({
      offset: {
        top: 50,
      },
    });
  }
}

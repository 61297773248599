import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { MainLpComponent } from './main-lp/main-lp.component';
import { LimitPlanComponent } from './limit-plan/limit-plan.component';

const routes: Routes = [
  {
    path: '',
    component: MainLpComponent,
  },
  {
    path: 'for-startup',
    component: LimitPlanComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Component, OnInit } from "@angular/core";
declare var ScrollReveal: any;
@Component({
  selector: "page-sympathy",
  templateUrl: "./page-sympathy.component.html",
  styleUrls: ["./page-sympathy.component.scss"],
})
export class PageSympathyComponent implements OnInit {
  ngOnInit() {
    // ScrollReveal().reveal(".download");
  }
}
